<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>线路管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="当前线路" icon="ios-subway-outline">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="lineManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="添加线路" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form
                ref="addLine"
                :model="newLine"
                :rules="ruleValidate"
                :label-width="150"
              >
                <Row>
                    <i-col span="12">
                      <FormItem label="线路名称：" prop="lineName">
                  <Input v-model="newLine.lineName" placeholder="X号线" />
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="线路编码：" prop="lineCode" >
                  <Input v-model="newLine.lineCode" placeholder="数字决定排列顺序" />
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="轨道类型：" prop="trackStyle">
                  <Select v-model="newLine.trackStyle" >
                    <Option
                      v-for="item in option.trackStyleChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="线路状态：" prop="stat">
                  <Select v-model="newLine.stat">
                    <Option
                      v-for="item in option.statList"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="供电方式：" prop="powerStyle">
                  <Select v-model="newLine.powerStyle">
                    <Option
                      v-for="item in option.powerStyleChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="车型：" prop="carriageStyle">
                  <Select v-model="newLine.carriageStyle">
                    <Option
                      v-for="item in option.carriageStyleChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="请选择线路颜色：" prop="lineColor">
                  <ColorPicker v-model="newLine.lineColor" />
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="建设期数：" prop="term">
                  <InputNumber
                    :max="10"
                    :min="1"
                    v-model="newLine.term"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="开通运营日期：" prop="openDate">
                  <input
                    type="date"
                    placeholder=""
                    v-model="newLine.openDate"
                  />
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="里程（km）：" prop="length">
                  <InputNumber
                    :max="1000"
                    :min="1"
                    :step="0.1"
                    v-model="newLine.length"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="车站数量：" prop="stationAmount">
                  <InputNumber
                    :max="100"
                    :min="2"
                    v-model="newLine.stationAmount"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="配车数：" prop="carriageAmount">
                  <InputNumber
                    :max="20"
                    :min="1"
                    v-model="newLine.carriageAmount"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="最高时速（KM/H）：" prop="maxSpeed">
                  <InputNumber
                    :max="400"
                    :min="1"
                    v-model="newLine.maxSpeed"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="最大埋深（米）：" prop="maxDepth">
                  <InputNumber
                    :max="1000"
                    :min="1"
                    v-model="newLine.maxDepth"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="最大坡度（度）：" prop="maxSlope">
                  <InputNumber
                    :max="60"
                    :min="1"
                    v-model="newLine.maxSlope"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="最小转弯半径（米）：" prop="minRadius">
                  <InputNumber
                    :max="500"
                    :min="1"
                    v-model="newLine.minRadius"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="定员载客量：" prop="specifiedCapacity">
                  <InputNumber
                    :max="10000"
                    :min="1"
                    v-model="newLine.specifiedCapacity"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="超员载客量：" prop="maxCapacity">
                  <InputNumber
                    :max="10000"
                    :min="1"
                    v-model="newLine.maxCapacity"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                <FormItem label="运行时间：">
                  <Row>
                    <i-col span="11">
                      <FormItem prop="startTime">
                        <TimePicker
                          type="time"
                          placeholder="开始时间"
                          v-model="newLine.startTime"
                        ></TimePicker>
                      </FormItem>
                    </i-col>
                    <i-col span="2" style="text-align: center">-</i-col>
                    <i-col span="11">
                      <FormItem prop="endTime">
                        <TimePicker
                          type="time"
                          placeholder="结束时间"
                          v-model="newLine.endTime"
                        ></TimePicker>
                      </FormItem>
                    </i-col>
                  </Row>
                </FormItem>
                <FormItem label="起止站点：">
                  <Row>
                    <i-col span="11">
                      <FormItem prop="startStation">
                        <Input v-model="newLine.startStation" placeholder="" />
                      </FormItem>
                    </i-col>
                    <i-col span="2" style="text-align: center">-</i-col>
                    <i-col span="11">
                      <FormItem prop="endStation">
                        <Input v-model="newLine.endStation" placeholder="" />
                      </FormItem>
                    </i-col>
                  </Row>
                </FormItem>
                <FormItem label="线路简介：" prop="description">
                  <Input v-model="newLine.description" placeholder="" style="width: 428px" type="textarea"/>
                </FormItem>
                <FormItem label="运营公司：" prop="operationCompany">
                  <Input v-model="newLine.operationCompany" placeholder="" />
                </FormItem>
                <FormItem label="车辆维保：" prop="carriageMaintenanceCompany">
                  <Input
                    v-model="newLine.carriageMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem
                  label="通号维保："
                  prop="communicationMaintenanceCompany"
                >
                  <Input
                    v-model="newLine.communicationMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="供电维保：" prop="powerMaintenanceCompany">
                  <Input
                    v-model="newLine.powerMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="工务维保：" prop="workMaintenanceCompany">
                  <Input
                    v-model="newLine.workMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addLine')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addLine')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含线路相关数据的excel文件：
                  <a href="/public_files/templates/LineTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                iurl="/line_alter"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>线路信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterLine"
                :model="currentLine"
                :rules="ruleValidate"
                :label-width="150"
              >
                <Row>
                    <i-col span="12">
                      <FormItem label="线路名称：" prop="lineName">
                  <Input v-model="currentLine.lineName" placeholder="X号线" />
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="线路编码：" prop="lineCode" >
                  <Input v-model="currentLine.lineCode" placeholder="数字决定排列顺序" />
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="轨道类型：" prop="trackStyle">
                  <Select v-model="currentLine.trackStyle" >
                    <Option
                      v-for="item in option.trackStyleChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="线路状态：" prop="stat">
                  <Select v-model="currentLine.stat">
                    <Option
                      v-for="item in option.statList"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="供电方式：" prop="powerStyle">
                  <Select v-model="currentLine.powerStyle">
                    <Option
                      v-for="item in option.powerStyleChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="车型：" prop="carriageStyle">
                  <Select v-model="currentLine.carriageStyle">
                    <Option
                      v-for="item in option.carriageStyleChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="请选择线路颜色：" prop="lineColor">
                  <ColorPicker v-model="currentLine.lineColor" />
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="建设期数：" prop="term">
                  <InputNumber
                    :max="10"
                    :min="1"
                    v-model="currentLine.term"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="开通运营日期：" prop="openDate">
                  <input
                    type="date"
                    placeholder=""
                    v-model="currentLine.openDate"
                  />
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="里程（km）：" prop="length">
                  <InputNumber
                    :max="1000"
                    :min="1"
                    :step="0.1"
                    v-model="currentLine.length"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="车站数量：" prop="stationAmount">
                  <InputNumber
                    :max="100"
                    :min="2"
                    v-model="currentLine.stationAmount"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="配车数：" prop="carriageAmount">
                  <InputNumber
                    :max="20"
                    :min="1"
                    v-model="currentLine.carriageAmount"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="最高时速（KM/H）：" prop="maxSpeed">
                  <InputNumber
                    :max="400"
                    :min="1"
                    v-model="currentLine.maxSpeed"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="最大埋深（米）：" prop="maxDepth">
                  <InputNumber
                    :max="1000"
                    :min="1"
                    v-model="currentLine.maxDepth"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="最大坡度（度）：" prop="maxSlope">
                  <InputNumber
                    :max="60"
                    :min="1"
                    v-model="currentLine.maxSlope"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="最小转弯半径（米）：" prop="minRadius">
                  <InputNumber
                    :max="500"
                    :min="1"
                    v-model="currentLine.minRadius"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                  <Row>
                    <i-col span="12">
                      <FormItem label="定员载客量：" prop="specifiedCapacity">
                  <InputNumber
                    :max="10000"
                    :min="1"
                    v-model="currentLine.specifiedCapacity"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem label="超员载客量：" prop="maxCapacity">
                  <InputNumber
                    :max="10000"
                    :min="1"
                    v-model="currentLine.maxCapacity"
                  ></InputNumber>
                </FormItem>
                    </i-col>
                  </Row>
                <FormItem label="运行时间：">
                  <Row>
                    <i-col span="11">
                      <FormItem prop="startTime">
                        <TimePicker
                          type="time"
                          placeholder="开始时间"
                          v-model="currentLine.startTime"
                        ></TimePicker>
                      </FormItem>
                    </i-col>
                    <i-col span="2" style="text-align: center">-</i-col>
                    <i-col span="11">
                      <FormItem prop="endTime">
                        <TimePicker
                          type="time"
                          placeholder="结束时间"
                          v-model="currentLine.endTime"
                        ></TimePicker>
                      </FormItem>
                    </i-col>
                  </Row>
                </FormItem>
                <FormItem label="起止站点：">
                  <Row>
                    <i-col span="11">
                      <FormItem prop="startStation">
                        <Input v-model="currentLine.startStation" placeholder="" />
                      </FormItem>
                    </i-col>
                    <i-col span="2" style="text-align: center">-</i-col>
                    <i-col span="11">
                      <FormItem prop="endStation">
                        <Input v-model="currentLine.endStation" placeholder="" />
                      </FormItem>
                    </i-col>
                  </Row>
                </FormItem>
                <FormItem label="线路简介：" prop="description">
                  <Input v-model="currentLine.description" placeholder="" style="width: 428px" type="textarea"/>
                </FormItem>
                <FormItem label="运营公司：" prop="operationCompany">
                  <Input v-model="currentLine.operationCompany" placeholder="" />
                </FormItem>
                <FormItem label="车辆维保：" prop="carriageMaintenanceCompany">
                  <Input
                    v-model="currentLine.carriageMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem
                  label="通号维保："
                  prop="communicationMaintenanceCompany"
                >
                  <Input
                    v-model="currentLine.communicationMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="供电维保：" prop="powerMaintenanceCompany">
                  <Input
                    v-model="currentLine.powerMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="工务维保：" prop="workMaintenanceCompany">
                  <Input
                    v-model="currentLine.workMaintenanceCompany"
                    placeholder=""
                  />
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterLine')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function lineFac() {
  return {
    id:0,
    lineCode:"",
    lineName: "",
    trackStyle: "",
    stat:"",
    description:"",
    lineColor: "",
    term: 0,
    openDate: "",
    length: 0,
    stationAmount: 0,
    carriageStyle: "",
    powerStyle: "",
    carriageAmount: 0,
    maxSpeed: 0,
    startTime: "",
    endTime: "",
    startStation: "",
    endStation: "",
    operationCompany: "",
    carriageMaintenanceCompany: "",
    powerMaintenanceCompany: "",
    workMaintenanceCompany: "",
    communicationMaintenanceCompany: "",
    maxDepth:0,
    maxSlope:0,
    minRadius:0,
    specifiedCapacity:0,
    maxCapacity:0
  };
}

export default {
  name: "lineManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      askData: "lineManage",
      update_count: 0,
      columnsFormatData: [
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
        },

        {
          title: "线路编码",
          key: "line_code",
          align: "center",
          width: 120,
        },
        {
          title: "轨道类型",
          key: "track_style",
          align: "center",
          width: 100,
        },
        {
          title: "线路状态",
          key: "stat",
          align: "center",
          width: 100,
        },
        {
          title: "车型",
          key: "carriage_style",
          align: "center",
          width: 100,
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      atimated: false,
      newLine: lineFac(),
      currentLine:lineFac(),
      ruleValidate: {
        lineCode: [
          {
            required: true,
            message: "线路编码不能为空",
            trigger: "blur",
          },
          {
            type: "string",
            max: 30,
            message: "线路编码不超过30字符",
            trigger: "change",
          },
        ],
        lineName: [
          {
            required: true,
            message: "线路名称不能为空",
            trigger: "blur",
          },
          {
            type: "string",
            max: 10,
            message: "线路名称不超过10字符",
            trigger: "change",
          },
        ],
      },
      option:{
          trackStyleChoices: [
        {
          value: "地铁",
          label: "地铁",
        },
        {
          value: "轻轨",
          label: "轻轨",
        },
      ],
      carriageStyleChoices: [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
      ],
      powerStyleChoices: [
        {
          value: "受电弓",
          label: "受电弓",
        },
        {
          value: "非受电弓",
          label: "非受电弓",
        },
      ],

      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.get_option_data();
  },
  methods: {
    get_option_data() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "lineManage",
        })
        .then((response) => {
          this.option = response.data.res_record;
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addLine");
          form.append("newLine", JSON.stringify(this.newLine));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/line_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("线路添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterLine");
          form.append("currentLine", JSON.stringify(this.currentLine));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/line_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("线路修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条线路信息：",
        content:
          "<p>" +
          "线路名称：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "编码：" +
          value.line_code +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/line_alter", {
          userToken: userToken,
          askData: "delLine",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value){
        this.currentLine = lineFac();
        this.currentLine.id = value.id;
        this.currentLine.lineCode = value.line_code;
        this.currentLine.lineName = value.line_name;
        this.currentLine.trackStyle = value.track_style;
        this.currentLine.stat = value.stat;
        this.currentLine.description = value.description;
        this.currentLine.lineColor = value.line_color;
        this.currentLine.term = value.term;
        this.currentLine.openDate = value.open_date;
        this.currentLine.length = value.length;
        this.currentLine.stationAmount = value.station_amount;
        this.currentLine.carriageStyle = value.carriage_style;
        this.currentLine.powerStyle = value.power_style;
        this.currentLine.carriageAmount = value.carriage_amount;
        this.currentLine.maxSpeed = value.max_speed;
        this.currentLine.startTime = value.start_time;
        this.currentLine.endTime = value.end_time;
        this.currentLine.startStation = value.start_station;
        this.currentLine.endStation = value.end_station;
        this.currentLine.operationCompany = value.operation_company;
        this.currentLine.carriageMaintenanceCompany = JSON.parse(value.maintenance_company).carriage;
        this.currentLine.powerMaintenanceCompany = JSON.parse(value.maintenance_company).power;
        this.currentLine.workMaintenanceCompany = JSON.parse(value.maintenance_company).work;
        this.currentLine.communicationMaintenanceCompany = JSON.parse(value.maintenance_company).communication;
        this.currentLine.maxDepth = value.max_depth;
        this.currentLine.maxSlope = value.max_slope;
        this.currentLine.minRadius = value.min_radius;
        this.currentLine.specifiedCapacity = value.specified_capacity;
        this.currentLine.maxCapacity = value.max_capacity;
        this.scModal = true;
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}

input{
  display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #515a6e;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
}

input:focus {
    border-color: #57a3f3;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(45,140,240,.2);
}

input:hover {
    border-color: #57a3f3;
}
</style>